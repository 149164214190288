<template>
  <td @click="setSelected(rowIndex, colIndex)" :class="cellClass">
    {{ cellValue }}
  </td>
</template>

<script>
import { mapState } from "vuex";
import { getGridCellClass } from "@/config/stores/common";

export default {
  name: "KakuroLetterCell",
  props: {
    rowIndex: {
      type: Number,
      required: true,
    },
    colIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState("kakuroStore", {
      grid: (state) => state.grid,
      solutionAchieved: (state) => state.solutionAchieved,
    }),
    cellValue() {
      return this.grid[this.rowIndex][this.colIndex].solution;
    },
    cellClass() {
      let baseClass = "";
      return getGridCellClass(
        this.grid,
        this.rowIndex,
        this.colIndex,
        this.solutionAchieved,
        baseClass
      );
    },
  },
  methods: {
    setSelected(x, y) {
      if (!this.grid[x][y].locked) {
        this.$store.commit("kakuroStore/setSelected", { x, y });
      }
    },
  },
};
</script>

<style scoped></style>
