<template>
  <td :id="tdId" :class="cellClass" v-html="hintValue"></td>
</template>

<script>
import { mapState } from "vuex";
import {getGridCellClass} from "@/config/stores/common";

export default {
  name: "KakuroHintCell",
  props: {
    rowIndex: {
      type: Number,
      required: true,
    },
    colIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState("kakuroStore", {
      numRows: (state) => state.numRows,
      numCols: (state) => state.numCols,
      grid: (state) => state.grid,
    }),
    cellClass() {
      let baseClass = "";
      return getGridCellClass(
        this.grid,
        this.rowIndex,
        this.colIndex,
        this.solutionAchieved,
        baseClass
      );
    },
    hintNumber() {
      return this.grid[this.rowIndex][this.colIndex].value;
    },
    tdId() {
      return `hint_${this.rowIndex}_${this.colIndex}`;
    },
    hasEastHint() {
      return (
        "he" in this.grid[this.rowIndex][this.colIndex] &&
        this.grid[this.rowIndex][this.colIndex]["he"] !== ""
      );
    },
    hasSouthHint() {
      return (
        "hs" in this.grid[this.rowIndex][this.colIndex] &&
        this.grid[this.rowIndex][this.colIndex]["hs"] !== ""
      );
    },
    eastHint() {
      return this.grid[this.rowIndex][this.colIndex].he;
    },
    southHint() {
      return this.grid[this.rowIndex][this.colIndex].hs;
    },
    hintValue() {
      if (this.hasEastHint && this.hasSouthHint) {
        return (
          this.eastHint + " / "  + this.southHint
        );
      } else if (this.hasEastHint) {
        return this.eastHint;
      } else if (this.hasSouthHint) {
        return this.southHint;
      }
      return "";
    },
  },
};
</script>

<style scoped>
td {
  cursor: default !important;
}

</style>
