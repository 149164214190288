<template>
  <div>
    <GameMessages
        v-bind:all-filled-up="allFilledUp"
        v-bind:has-wrong-values="hasWrongValues"
        v-bind:game="game"
        v-bind:period="period"
    >
    </GameMessages>
    <hr/>
    <table class="rm-table-crossword">
      <tbody>
      <tr v-for="(row, idx) in grid" :key="idx">
        <kakuro-cell
            v-for="(cell, idy) in row"
            :key="idy"
            v-bind:row-index="idx"
            v-bind:col-index="idy"
        ></kakuro-cell>
      </tr>
      </tbody>
    </table>
    <TableValues
        v-bind:game="game"
        v-bind:game-store-obj="this.$store"
        v-bind:valid-values="validValues"
        v-bind:num-values-per-row="11"
    ></TableValues>
    <GameActions
        v-bind:game="game"
        v-bind:showed-actions="showedActions"></GameActions>
  </div>
</template>

<script>
import {mapState} from "vuex";
import KakuroCell from "@/components/games/kakuro/KakuroCell";
import GameMessages from "@/components/games/GameMessages.vue";
import GameActions from "@/components/games/GameActions.vue";
import {handleKeyPress} from "@/config/stores/common";
import TableValues from "@/components/games/TableValues.vue";

export default {
  name: "KakuroGrid",
  props: {
    game: {
      type: String,
      default: "kakuro"
    },
    gameStore: {
      type: String,
      default: "kakuroStore"
    },
    period: {
      type: String,
      default: "",
    },
    gameId: {
      type: String,
      default: "",
    },
    showedActions: {
      type: Array,
      default: () => [],
    }
  },
  components: {
    TableValues,
    GameActions,
    KakuroCell,
    GameMessages,
  },
  computed: {
    ...mapState("kakuroStore", {
      completed: (state) => state.completed,
      allFilledUp: (state) => state.allFilledUp,
      hasWrongValues: (state) => state.hasWrongValues,
      grid: (state) => state.grid,
    }),
    validValues() {
      return ["1", "2", "3", "4", "5", "6", "7", "8", "9", ""];
    },
    ...mapState({
      account: (state) => state.account,
    }),
    gameStoreObj() {
      return this.$store
    },
  },
  methods: {
    handleKeyPress(e) {
      e.preventDefault();
      handleKeyPress(e, this.validValues, this.gameStore, this.gameStoreObj)
    },
  },
  created() {
    this.$store.commit(this.game + "Store/initGrid", {
      period: this.period,
      gridId: this.gameId,
    });
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyPress);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyPress);
  },
};
</script>

<style lang="scss" scoped></style>
