<template>
  <td class="rm-locked">E</td>
</template>

<script>
export default {
  name: "KakuroEmptyCell",
};
</script>

<style scoped>
td {
  cursor: default;
}
</style>
