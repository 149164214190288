<template>
  <kakuro-letter-cell
    v-if="grid[rowIndex][colIndex].type === 'L'"
    v-bind:row-index="rowIndex"
    v-bind:col-index="colIndex"
  ></kakuro-letter-cell>
  <kakuro-hint-cell
    v-else-if="grid[rowIndex][colIndex].type === 'H'"
    v-bind:row-index="rowIndex"
    v-bind:col-index="colIndex"
  ></kakuro-hint-cell>
  <kakuro-empty-cell
    v-else-if="grid[rowIndex][colIndex].type === 'E'"
    v-bind:row-index="rowIndex"
    v-bind:col-index="colIndex"
  ></kakuro-empty-cell>
</template>

<script>
import { mapState } from "vuex";

import KakuroEmptyCell from "@/components/games/kakuro/KakuroEmptyCell";
import KakuroHintCell from "@/components/games/kakuro/KakuroHintCell";
import KakuroLetterCell from "@/components/games/kakuro/KakuroLetterCell";

export default {
  name: "KakuroCell",
  components: {
    KakuroEmptyCell,
    KakuroHintCell,
    KakuroLetterCell,
  },
  props: {
    rowIndex: {
      type: Number,
      required: true,
    },
    colIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState("kakuroStore", {
      grid: (state) => state.grid,
    }),
  },
};
</script>

<style scoped>
td {
  border: 1px solid;
  width: 1em;
  max-width: 1em;
  height: 2em;
  max-height: 2em;
}
</style>
